export default (type) => {
    switch (type) {
        case 'journals': return '"Журналы"'
        case 'plans': return '"Задачи"'
        case 'organizational_structure': return '"Организационные структуры"'
        case 'users': return '"Пользователи"'
        case 'guides': return '"guides"'
        case 'results': return '"Результаты"'
        case 'permissions': return '"Права"'
        case 'materials': return '"Материалы"'
        case 'tests': return '"Тесты"'
        case 'trainings': return '"Обучение"'
        case 'reports': return '"Отчеты"'
        case 'help': return '"Помощь"'
        default:return type
    }
}