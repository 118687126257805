import { actionCreator } from '../action-created'

import {SHOW_MODAL_PAGE,
        HIDE_MODAL_PAGE,
        SHOW_DIALOG,
        HIDE_DIALOG,
        SHOW_SNACKBAR,
        HIDE_SNACKBAR
} from './key'

export const showModalPage = (payload) => actionCreator(SHOW_MODAL_PAGE, payload)
export const hideModalPage = () => actionCreator(HIDE_MODAL_PAGE)
export const showDialog = (payload) => actionCreator(SHOW_DIALOG, payload)
export const hideDialog = () => actionCreator(HIDE_DIALOG)
export const showSnackbar = (payload) => actionCreator(SHOW_SNACKBAR, payload)
export const hideSnackbar = (payload) => actionCreator(HIDE_SNACKBAR, payload)