import React from 'react'
import moment from 'moment'
import { isEmpty, isBoolean, pickBy, find } from 'lodash'
import AsyncSelect from './async-multiselect'
import webApi from '../../../../../../utilities/web-api'
import inclusiveSearchConstructor from './../../../../../elements/search-contructor'
import Select from 'react-select'

export default ({ submit, statusValues }) => {
  const [state, setState] = React.useState({
    from_date: moment().subtract(3, 'month').format('YYYY-MM-DD'),
    due_date: moment().format('YYYY-MM-DD'),
    status: '',
    users: [],
    departments: [],
    filials: [],
    limit: 50,
    offset: 0
  })

  const prepareStateFields = obj => {
    const result = pickBy(obj, field => isBoolean(field) || !isEmpty(field))
    result.limit = obj.limit
    result.offset = obj.offset
    if (result.from_date) result.from_date = new Date(result.from_date).toISOString()
    if (result.due_date) {
      result.due_date = moment(result.due_date)
        .add(23, 'h')
        .add(59, 'm')
        .add(59, 's')
        .toISOString()
    }
    return result
  }

  const emitData = () => {
    submit(prepareStateFields(state))
  }

  const isEmptyData = () => isEmpty(state.departments)
    && isEmpty(state.from_date)
    && isEmpty(state.due_date)
    && !isBoolean(state.finish)
    && isEmpty(state.users)
    && isEmpty(state.filials)

  const handleChange = event => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    })
  }

  const handleDueSelect = e => {
    const { value: status } = e
    setState({ ...state, status })
  }

  const onValue = (name, value) => setState({ ...state, [name]: value })
  const userReducer = ({ id, name, surname }) => ({ value: id, label: `${name} ${surname}` })

  return (
    <div className="block-content">
      <div className="row-report">
        <div className="row-report-title">Дата начала вхождения</div>
        <input defaultValue={state.from_date} type="date"  name="from_date" onChange={handleChange}/>
      </div>

      <div className="row-report">
        <div className="row-report-title">Дата окончания вхождения</div>
        <input defaultValue={state.due_date} type="date" name="due_date" onChange={handleChange}/>
      </div>

      <div className="row-report">
        <div className="row-report-title">Статус</div>
        <Select
          name="status"
          className="select-report"
          id="status"
          onChange={handleDueSelect}
          defaultValue={statusValues[0]}
          options={statusValues}/>
      </div>

      <div className="clear"/>

      <div className="row-report">
        <div className="row-report-title">Отдел</div>
        <AsyncSelect
          submit={onValue}
          findCallback={inclusiveSearchConstructor(
          webApi.inclusiveSearchDepartments,
          'Такой отдел не найден.'
          )}
          placeholder="Отдел"
          name='departments'
        />
      </div>

      <div className="row-report">
        <div className="row-report-title">Объект</div>
        <AsyncSelect
          submit={onValue}
          findCallback={inclusiveSearchConstructor(
            webApi.inclusiveSearchFilials,
            'Такой объект не найден.'
          )}
          placeholder="Выберите объект"
          name='filials'
        />
      </div>

      <div className="row-report">
        <div className="row-report-title">Пользователь</div>
        <AsyncSelect
          submit={onValue}
          findCallback={inclusiveSearchConstructor(
            webApi.inclusiveSearchUser,
            'Такой пользователь не найден.',
            userReducer
          )}
          placeholder="Выберите пользователей"
          name='users'
        />
      </div>

      <div className="btn-report fl-right">
        <button disabled={isEmptyData()} onClick={emitData} className="btnSaveProfile fl-right">Сформировать</button>
      </div>

      <div className="clear"/>
   </div>
  )
}
