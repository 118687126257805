import { pushPageName, setUnreadMessagesCount } from '../store/actions/header/action'
import { addBrdc, newBrdc, delBrdc } from '../store/actions/breadcrumbs/action'
import {showSnackbar, showDialog, hideDialog, showModalPage, hideModalPage } from '../store/actions/elements/action'
import {setUser, clearToken} from '../store/actions/client/action'
import store from '../store/index'
import webApi from './web-api';
import history from '../components/client/right-panel/history';


function addBreadcrumps(name){
    return store.dispatch(addBrdc(name))
}

function newBreadcrumps(name){
    return store.dispatch(newBrdc(name))
}

function delLastBreadcrumps(){
    return store.dispatch(delBrdc())
}

function isMobile(){
    const ua = navigator.userAgent;

    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) return true
    else return false
}

function historyBack(){
    console.log(history)
    history.goBack()  
    delLastBreadcrumps()
}

function getSnackbar(title){
    return store.dispatch(showSnackbar(title))
}

function getDialog(payload){
    return store.dispatch(showDialog(payload))
}

function closeDialog(){
    return store.dispatch(hideDialog())
}

function getModalPage(payload){
    return store.dispatch(showModalPage(payload))
}

function closeModalPage(){
    let audio = document.querySelector('audio')
    audio && audio.pause()
    history.location.hash && history.goBack()
    return store.dispatch(hideModalPage())
}

function updateUser(data){
    return store.dispatch(setUser(data))
}

function clearSession(){
    localStorage.removeItem('token')
    return store.dispatch(clearToken())
}

function pageName(name){
   return store.dispatch(pushPageName(name))
}

function updateUnreadMessagesCount(count) {
    return store.dispatch(setUnreadMessagesCount(count))
}

function uploader(file, result, ratio){
    const formData  = new FormData();
    let obj = file;
    formData.append('file', obj, obj.name);

    webApi.setUpload(formData, ratio)
    .then(data => {
        if(data.status === 200){
            getSnackbar('Файл успешно загружен')
            return result(data)
        }
        else if(data.status === 409) {
            getSnackbar('Данный файл уже существует')
            return result()
        }
        else if(data.status === 400) {
            getSnackbar('Некорректное название файла. Файл должен содержать только латинские буквы и цифры')
            return result()
        }
        else return getSnackbar('Произошла неизвестная ошибка, попробуйте позже')
    })
    .catch(() => getSnackbar('На данный момент сервис загрузки файлов не отвечает, повторите попытки позже'))
}

export const header = ({
    historyBack,
    pageName,
    addBreadcrumps,
    newBreadcrumps,
    delLastBreadcrumps,
    getSnackbar,
    getDialog,
    closeDialog,
    isMobile,
    getModalPage,
    closeModalPage,
    clearSession,
    updateUser,
    uploader,
    updateUnreadMessagesCount
})


