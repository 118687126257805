import React, { Component } from 'react'
import Breadcrumbs from './../contents/breadcrumbs';
import { header } from './../../../../utilities/page';
import UserHeaderPop from './user-pop'

export default class ViewHeader extends Component {
  state = {
    open: false
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  componentWillMount(){
    localStorage.getItem('_brdc') && header.addBreadcrumps(JSON.parse(localStorage.getItem('_brdc')))
  }

  menuToggle = async() => {
    let menu = await document.querySelector('.left-content')
    await menu.classList.toggle('menu-min')
  }

  render() {
    const { surname, name, images } = this.props.user
    const { page_name } = this.props

    return (
        <div className="header-right-content flex-content">
              <div className="flex-children">
                  <div className="flex"><i onClick={this.menuToggle} className="fas fa-bars"></i></div>
                  <div>
                    <span className="title">{ page_name }</span> <br/>
                    <span className="pagin"><Breadcrumbs/></span>
                  </div>
              </div>

              <div className="flex-children flex-end">
                  <div className="notif-header-block">
                    <i className={`connection-icon ${this.props.connected ? 'connect' : 'disconnect'} fal fa-wifi`}/>
                  </div>
                  <button className="user-header-block">
                      <div className="fl-left">
                          {images ? <img src={images && images.normal} className="ava-profile ava-chat-fix" width="40px" height="40px" alt=""/> : <div className="ava-chat ava-chat-fix"></div>}
                           {/*<img src={ avatar } alt="" className="avatar-min" />*/}
                      </div>
                      <div className="fl-right fixProfileName"><b>{`${surname} ${name}`}</b></div>
                       { <UserHeaderPop /> }
                  </button>
              </div>
          </div>
    )
  }
}
