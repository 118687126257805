import React, { Component } from "react";
import PreloaderComponent from "./application/utilities/preloader";
import Auth from "./application/components/auth/index";
import Client from "./application/components/client/index";
import webApi from "./application/utilities/web-api";
import { getToken, setUser } from "./application/store/actions/client/action";
import { connect } from "react-redux";
import Snackbar from "./application/components/elements/modals/snackbar";
import Dialog from "./application/components/elements/modals/dialog";
import ModalPage from "./application/components/elements/modals/modalPage";
import history from "./application/components/client/right-panel/history";
import Helper from "./application/components/client/right-panel/helper/index";
import PdfViewer from "./application/components/client/right-panel/pdf";
import Mobile from "./application/components/client/right-panel/mobile";

class App extends Component {
  componentWillMount = async () => {
    await this.props.getToken(localStorage.getItem("token"));
    const token = await this.props.state.token;
    (await token) &&
      webApi
        .getProfile(token)
        .then((data) => this.props.setUser(data))
        .catch(console.error);

    window.addEventListener("onhashchange", () => {
      console.log("hash");
    });
  };

  availabilityAgent = () => {
    const ua = navigator.userAgent;

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      )
    )
      return true;
    else return false;
  };

  //this.availabilityAgent() ? <Mobile /> :

  render() {
    const { token, user_data } = this.props.state;
    return (
      <div>
        {
          /*this.availabilityAgent() ? <Mobile />
          : */

          token && user_data && user_data.name ? (
            <PreloaderComponent component={<Client user={user_data} />} />
          ) : (
            <PreloaderComponent component={<Auth />} />
          )
        }
        <Snackbar />
        <Dialog />
        <ModalPage />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: state.user,
  };
};

const mapDispatchToProps = { getToken, setUser };

export default connect(mapStateToProps, mapDispatchToProps)(App);
