import store from './../store/index'
import Helpers from './helpers'

const {
    getExcludeType,
    getMaterialType
} = Helpers

const apiUrl = process.env.REACT_APP_APP_URL || 'http://10.0.1.17/api'
const params = {
    url: process.env.REACT_APP_APP_URL,
    method: 'GET',
    headers: {
        'Content-Type' : 'application/json',
        'Authorization' : null
    },
    body: null
}

let token

store.subscribe(() => {
    token = store.getState().user.token
    params.headers['Authorization'] = token
})

let {url} = params

const query = (endPoint, obj = params) => fetch(url + endPoint, obj)


const signIn = async body => {
    const options = await {...params, method: 'POST', body: JSON.stringify(body) }

    let request = await query('/auth/signin', options)
    let response = await request.json()
    return {...response, status: request.status}
}
///auth/recovery
const recoveryPassword = async body => {
    const options = await {...params, method: 'POST', body: JSON.stringify(body) }

    let request = await query('/auth/recovery', options)
    let response = await request.json()
    return {...response, status: request.status}
}
const confirmPassword = async body => {
    const options = await {...params, method: 'PUT', body: JSON.stringify(body) }

    let request = await query('/auth/recovery', options)
    let response = await request.json()
    return {...response, status: request.status}
}
const signUp = async body => {
    const options = await {...params, method: 'POST', body: JSON.stringify(body) }

    let request = await query('/auth/account', options)
    let response = await request.json()
    return {...response, status: request.status}
}
const updatePassword = async (body) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body) }

    let request = await query('/auth/password', options)
    let response = await request.json()
    return {...response, status: request.status}
}

const updateTask = async (id, body) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body) }

    let request = await query(`/user/task/${id}`, options)
    let response = await request.json()
    return {...response, status: request.status}
}

const updateProgramOrder = async (body) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body) }

    let request = await query('/content/program/order', options)
    let response = await request.json()
    return { response, status: request.status }
}

const updateCourseOrder = async (body) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body) }

    let request = await query('/content/course/order', options)
    let response = await request.json()
    return { response, status: request.status }
}

const updateLectureOrder = async (body) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body) }

    let request = await query('/content/lecture/order', options)
    let response = await request.json()
    return { response, status: request.status }
}

const updateProgram = async (body) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body) }

    let request = await query('/content/program', options)
    let response = await request.json()
    return {...response, status: request.status}
}

const updateProfile = async (body) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body) }

    let request = await query('/user/profile', options)
    let response = await request.json()
    return {...response, status: request.status}
}

const updateProfileId = async (body,id) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body) }

    let request = await query('/user/profile/'+id, options)
    let response = await request.json()
    return {...response, status: request.status}
}

const updatePositionId = async (body,id) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body) }

    let request = await query('/user/position/'+id, options)
    let response = await request.json()
    return {...response, status: request.status}
}

const phoneUpdate = async (body) =>{
    const options = await {...params, method: 'POST', body: JSON.stringify(body) }

    let request = await query('/user/phone', options)
    let response = await request.json()
    return {...response, status: request.status}
}

const phoneConfirm = async (body) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body) }

    let request = await query('/user/phone', options)
    let response = await request.json()
    return {...response, status: request.status}
}



const emailUpdate = async (body) =>{
    const options = await {...params, method: 'POST', body: JSON.stringify(body) }

    let request = await query('/user/email', options)
    let response = await request.json()
    return {...response, status: request.status}
}

const emailConfirm = async (body) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body) }

    let request = await query('/user/email', options)
    let response = await request.json()
    return {...response, status: request.status}
}

const setAward = async (body) =>{
    const options = await {...params, method: 'POST', body: JSON.stringify(body) }

    let request = await query('/content/award', options)
    let response = await request.json()
    return {...response, status: request.status}
}

const setPosition = async (body) =>{
    const options = await {...params, method: 'POST', body: JSON.stringify(body) }

    let request = await query('/user/position', options)
    let response = await request.json()
    return {...response, status: request.status}
}

const setAvailability = async (body) =>{
    const options = await {...params, method: 'POST', body: JSON.stringify(body) }

    let request = await query('/user/availability', options)
    let response = await request.json()
    return {response, status: request.status}
}

const setTest = async (body) =>{
    const options = await {...params, method: 'POST', body: JSON.stringify(body) }

    let request = await query('/content/test', options)
    let response = await request.json()
    return {response, status: request.status}
}

const updateTest = async (body) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body) }

    let request = await query('/content/test', options)
    let response = await request.json()
    return {response, status: request.status}
}

const setQuestion = async (body) =>{
    const options = await {...params, method: 'POST', body: JSON.stringify(body) }

    let request = await query('/content/question', options)
    let response = await request.json()
    return {response, status: request.status}
}

const deleteQuestion = async (id) =>{
    const options = await {...params, method: 'DELETE' }

    let request = await query('/content/question/'+id, options)
    let response = await request.json()
    return {response, status: request.status}
}

const insertQuestions = async body =>{
    const options = {
        ...params,
        method: 'PATCH',
        body: JSON.stringify(body)
    }
    const request = await query('/content/question', options)
    const response = await request.json()
    return request.status === 200
      ? Promise.resolve(response)
      : Promise.reject(response.error)
}

const deleteTask = async (id) =>{
    const options = await {...params, method: 'DELETE' }

    let request = await query('/user/task/'+id, options)
    let response = await request.json()
    return {response, status: request.status}
}

const deleteAvailability = async (id) =>{
    const options = await {...params, method: 'DELETE' }

    let request = await query('/user/availability/'+id, options)
    let response = await request.json()
    return {response, status: request.status}
}
///content/additional_material/{id}
const deleteAdditionMaterial = async (id) =>{
    const options = await {...params, method: 'DELETE' }

    let request = await query('/content/additional_material/'+id, options)
    let response = await request.json()
    return {response, status: request.status}
}
const deleteAnswer = async (id) =>{
    const options = await {...params, method: 'DELETE' }

    let request = await query('/content/answer/'+id, options)
    let response = await request.json()
    return {response, status: request.status}
}

const getTestQuestions = async id => {///content/creator/question
    let request = await query('/content/creator/question/'+id)
    let response = await request.json()
    return {response, status: request.status}
}

const getTimeLine = async (tId) =>{
    let id = tId ? `/${tId}` : ''
    let request = await query('/user/timelines' + id)
    let response = await request.json()
    return {response, status: request.status}
}

const getTimeLineId = async (id) =>{
    let request = await query('/user/timelines/' + id)
    let response = await request.json()
    return {response, status: request.status}
}

const setAnswer = async (body) =>{
    const options = await {...params, method: 'POST', body: JSON.stringify(body) }

    let request = await query('/content/answer', options)
    let response = await request.json()
    return {response, status: request.status}
}

const updateAnswer = async (id,body) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body) }

    let request = await query('/content/answer/'+id, options)
    let response = await request.json()
    return {response, status: request.status}
}

const updateAnswerBody = async (body) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body) }

    let request = await query('/content/answer', options)
    let response = await request.json()
    return {response, status: request.status}
}

const updateAnswerOption = async (body) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body) }

    let request = await query('/content/question', options)
    let response = await request.json()
    return {response, status: request.status}
}

const getAchievement = async (id) =>{
    let request = await query('/content/achievements/'+id)
    let response = await request.json()
    return {response, status: request.status}
}

const getAchievements = async () =>{
    let request = await query('/content/achievements')
    let response = await request.json()
    return {response, status: request.status}
}

const getProfile = async () =>{
    let request = await query('/user/profile')
    let response = await request.json()
    return {...response, status: request.status}
}

const getProfiles = async (limit = 50, offset = 0, status) =>{
    let statusUser = status && (status !== null && status !== 'all') ? `&status=${status}` : ''
    let request = await query(`/user/profiles?limit=${limit}&offset=${offset}${statusUser}`)
    let response = await request.json()
    return {response, status: request.status}
}

const getProfilesAll = async (status) =>{
    let statusUser = status && (status !== null && status !== 'all') ? `&status=${status}` : ''
    let request = await query(`/user/profiles?should_limit=false${statusUser}`)
    let response = await request.json()
    return {response, status: request.status}
}

const getProfilesAllLike = async (status, filter) =>{
    let filterUsers = {
        name: filter.name ? `&name=${filter.name}` : '',
        surname: filter.surname ? `&surname=${filter.surname}` : '',
        phone: filter.phone ? `&phone=${filter.phone}` : ''
    }
    let statusUser = status && (status !== null && status !== 'all') ? `&status=${status}` : ''
    let request = await query(`/user/profiles?limit=50&offset=0${statusUser}${filterUsers.name}${filterUsers.surname}${filterUsers.phone}`)
    let response = await request.json()
    return {response, status: request.status}
}

const getPosition = async (limit = 50, offset = 0) =>{
    let request = await query(`/user/position?limit=${limit}&offset=${offset}`)
    let response = await request.json()
    return {response, status: request.status}
}

const getProfileId = async id =>{
    let request = await query('/user/profile/' + id)
    let response = await request.json()
    return {response, status: request.status}
}

const validToken = async () =>{
    let request = await query('/auth/is_valid_jwt')
    let response = await request.json()
    console.log(params)
    return {...response, status: request.status}
}

const getProgramms = async (limit = 50, offset = 0) =>{
    let request = await query(`/content/program?limit=${limit}&offset=${offset}`)
    let response = await request.json()
    return {response, status: request.status}
}

const getProgrammsAll = async () =>{
    let request = await query(`/content/program?should_limit=false`)
    let response = await request.json()
    return {response, status: request.status}
}

const getProgram = async (id) =>{
    let request = await query('/content/program/' + id)
    let response = await request.json()
    return {response, status: request.status}
}

const getPermissionList = async () =>{
    let request = await query('/user/permission/list')
    let response = await request.json()
    return {response, status: request.status}
}

const getPermissionUser = async (id) =>{

    let request = await query('/user/permission/' + id)
    let response = await request.json()
    return {response, status: request.status}
}

const pathPermissionUser = async (id, body) =>{
    const options = await {...params, method: 'PATCH', body: JSON.stringify(body)}
    let request = await query('/user/permission/' + id, options)
    let response = await request.json()
    return {response, status: request.status}
}

const getCompanyId = async (id) =>{
    let request = await query('/admin/company/' + id)
    let response = await request.json()
    return {response, status: request.status}
}

const getCategory = async () =>{
    let request = await query('/content/program_category?limit=50&offset=0')
    let response = await request.json()
    return {response, status: request.status}
}

const getCategoryId = async (id) =>{
    let request = await query('/content/program_category/' + id)
    let response = await request.json()
    return {response, status: request.status}
}

const getTeacher = async () =>{
    let request = await query('/user/role/teacher')
    let response = await request.json()
    return {response, status: request.status}
}

const getProgrmTeacher = async (id) =>{
    let request = await query('/content/program_teacher/'+id)
    let response = await request.json()
    return {response, status: request.status}
}

const getTest = async (test_id) =>{
    let request = await query('/content/test/' + test_id)
    let response = await request.json()
    return {response, status: request.status}
}

const getAward = async (id = '', limit = 50, offset = 0) =>{
    id ? id = `/${id}` : id = ''
    let request = await query('/content/award'+id + `?limit=${limit}&offset=${offset}`)
    let response = await request.json()
    return {response, status: request.status}
}

const getQuestion = async (test_id) =>{
    let request = await query('/content/question/' + test_id)
    let response = await request.json()
    return {response, status: request.status}
}

const getCourses = async (program_id, limit = 50, offset = 0) =>{
    let request = await query(`/content/courses/${program_id}?limit=${limit}&offset=${offset}`)
    let response = await request.json()
    return {response, status: request.status}
}

const getCourseId = async (id) =>{
    let request = await query(`/content/course/${id}`)
    let response = await request.json()
    return {response, status: request.status}
}

const getCourse = async (program_id) =>{
    let request = await query('/content/course/' + program_id)
    let response = await request.json()
    return {response, status: request.status}
}
/*
const getCourses = async (program_id, limit = 50, offset = 0) =>{
    let request = await query(`/content/courses/${program_id}?limit=${limit}&offset=${offset}`)
*/
const getLecture = async (course_id, limit = 50, offset = 0) =>{
    let request = await query(`/content/lectures/${course_id}?limit=${limit}&offset=${offset}`)
    let response = await request.json()
    return {response, status: request.status}
}

const getLectureId = async (course_id) =>{
    let request = await query('/content/lecture/' + course_id)
    let response = await request.json()
    return {response, status: request.status}
}

/*
const getFilial = async (id = null, limit = 50, offset = 0) =>{
    let ids = id ? `/${id}` : ''
    let request = await query(`/admin/filial${ids}?limit=${limit}&offset=${offset}`)
    let response = await request.json()

    return {response, status: request.status}
}
*/

const getAllAvailability = async (limit = 50, offset = 0) =>{
    let request = await query(`/user/availability?limit=${limit}&offset=${offset}`)
    let response = await request.json()
    return {response, status: request.status}
}

const getAllTasks = async (user, limit = 50, offset = 0) =>{
    let str = user ? `user=${user}&` : ''
    let request = await query(`/user/tasks?${str}limit=${limit}&offset=${offset}`)
    let response = await request.json()
    return {response, status: request.status}
}

const getTaskId = async id =>{
    let request = await query(`/user/task/${id}`)
    let response = await request.json()
    return {response, status: request.status}
}


const getHistory = async (id) =>{
    let request = await query('/content/history/' + id)
    let response = await request.json()
    return {response, status: request.status}
}
//
const getHistoryTest = async (id) =>{
    let request = await query('/content/history/test/' + id)
    let response = await request.json()
    return {response, status: request.status}
}

const getHistoryAttempts = async (id) =>{
    let request = await query('/content/history/attempts/' + id)
    let response = await request.json()
    return {response, status: request.status}
}

const getLesson = async (course_id) =>{
    let request = await query('/content/lecture/' + course_id)
    let response = await request.json()
    return {response, status: request.status}
}
const getHelperMobile = async (token) =>{
    const options = await {...params,
        headers: {
            'Content-Type' : 'application/json',
            'Authorization' : `bearer ${token}`
        }
    }
    let request = await query('/admin/help?limit=50&offset=0', options)
    let response = await request.json()
    return {response, status: request.status}
}
const getLessonMobile = async (id, token) =>{
    const options = await {...params,
        headers: {
            'Content-Type' : 'application/json',
            'Authorization' : `bearer ${token}`
        }
    }
    let request = await query('/content/lecture/' + id, options)
    let response = await request.json()
    return {response, status: request.status}
}

const setProgram = async (body) =>{
    const options = await {...params,
        method: 'POST',
        body: JSON.stringify(body),
    }

    let request = await query('/content/program', options)
    let response = await request.json()

    return {...response, status: request.status}
}

const putTeacher = async (id, from, to) =>{
    const options = await {...params,
        method: 'PUT',
        body: JSON.stringify({
            id, from_user_id: from, to_user_id: to
        }),
    }

    let request = await query('/content/program_teacher', options)
    let response = await request.json()

    return {...response, status: request.status}
}


const setCourse = async (body) =>{
    const options = await {...params,
        method: 'POST',
        body: JSON.stringify(body),
    }

    let request = await query('/content/course', options)
    let response = await request.json()

    return {...response, status: request.status}
}

const setCompany = async (body) =>{
    const options = await {...params, method: 'POST', body: JSON.stringify(body)}

    let request = await query('/admin/company', options)
    let response = await request.json()

    return {...response, status: request.status}
}

const setCategory = async (body) =>{
    const options = await {...params,
        method: 'POST',
        body: JSON.stringify(body),
    }

    let request = await query('/content/program_category', options)
    let response = await request.json()

    return {response, status: request.status}
}

const updateCompanyId = async (body) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body)}
    let request = await query('/admin/company', options)
    let response = await request.json()
    return {response, status: request.status}
}

const updateCategoryId = async (body) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body)}
    let request = await query('/content/program_category', options)
    let response = await request.json()
    return {response, status: request.status}
}

const updateDepartment = async (body) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body)}
    let request = await query('/admin/department', options)
    let response = await request.json()
    return {response, status: request.status}
}

const updateAwards = async (body) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body)}
    let request = await query('/content/award', options)
    let response = await request.json()
    return {response, status: request.status}
}
///admin/filial

const updateFilialId = async (body) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body)}
    let request = await query('/admin/filial', options)
    let response = await request.json()
    return {response, status: request.status}
}

const sendAnswer = async (body) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body)}

    let request = await query('/content/answer/history', options)
    let response = await request.json()

    return {...response, status: request.status}
}

const updateCourse = async (body) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body)}

    let request = await query('/content/course', options)
    let response = await request.json()

    return {...response, status: request.status}
}

const updateLecture = async (body) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body)}

    let request = await query('/content/lecture', options)
    let response = await request.json()

    return {...response, status: request.status}
}

const finishTest = async id => {
    const options = await {...params, method: 'POST'}

    let request = await query('/content/test/finish/'+id, options)
    let response = await request.json()

    return {...response, status: request.status}
}

const setHelper = async (body) => {
    const options = await {...params, method: 'POST', body: JSON.stringify(body)}

    let request = await query('/admin/help', options)
    let response = await request.json()

    return {response, status: request.status}
}

const banUser = async (id) => {
    const options = await {...params, method: 'POST'}

    let request = await query('/admin/user/ban/' + id, options)
    let response = await request.json()

    return {response, status: request.status}
}

const unbanUser = async (id) => {
    const options = await {...params, method: 'PUT'}

    let request = await query('/admin/user/ban/' + id, options)
    let response = await request.json()

    return {response, status: request.status}
}

const getRatings = async () => {
    const options = await {...params, method: 'POST'}

    let request = await query('/admin/report/rating', options)
    let response = await request.json()

    return {response, status: request.status}
}

const getRatingId = async (id) => {
    const options = await {...params, method: 'POST'}

    let request = await query('/admin/report/rating/' + id, options)
    let response = await request.json()

    return {response, status: request.status}
}

const updateHelper = async (id, body) =>{
    const options = await {...params, method: 'PUT', body: JSON.stringify(body)}
    let request = await query('/admin/help/' + id, options)
    let response = await request.json()
    return {response, status: request.status}
}

const getHelper = async () => {

    let request = await query('/admin/help?limit=50&offset=0')
    let response = await request.json()

    return {response, status: request.status}
}

const getHelperId = async (id) => {

    let request = await query('/admin/help/' + id)
    let response = await request.json()

    return {response, status: request.status}
}

//upload/file
const setUpload = async (body, ratio) =>{
    const options = {
        url: apiUrl,
        method: 'POST',
        headers: {
            Authorization: token
        },
        body
    }

    let request = await query(`/upload/file${ ratio ? `?ratio=${ratio}` : ''}`, options)
    let response = await request.json()

    return {...response, status: request.status}
}

const getUpload = async () =>{
    let request = await query('/upload/file')
    let response = await request.json()

    return {...response, status: request.status}
}

const getFiles = async () =>{
    let request = await query('/upload/file')
    let response = await request.json()

    return {response, status: request.status}
}

const setLecture = async (body) =>{
    const options = await {...params,
        method: 'POST',
        body: JSON.stringify(body),
    }

    let request = await query('/content/lecture', options)
    let response = await request.json()

    return {...response, status: request.status}
}

const getFilial = async (id = null, limit = 50, offset = 0) =>{
    let ids = id ? `/${id}` : ''
    let request = await query(`/admin/filial${ids}?limit=${limit}&offset=${offset}`)
    let response = await request.json()

    return {response, status: request.status}
}

const getCompany = async () =>{
    let request = await query(`/admin/company?limit=50&offset=0`)
    let response = await request.json()

    return {response, status: request.status}
}

const getCompanyLike = async str =>{
    let request = await query('/admin/company/like?query='+str+'&limit=50&offset=0')
    let response = await request.json()

    return {response, status: request.status}
}

const getTestLike = async str =>{
    let request = await query('/content/test/like?query='+str+'&limit=50&offset=0')
    let response = await request.json()

    return {response, status: request.status}
}

const fetchLectureAutogenerateQuestion = async id =>{
    const request = await query(`/content/autogenerate/question/${id}`)
    const response = await request.json()
    return request.status === 200
      ? Promise.resolve(response)
      : Promise.reject(response.error)
}

const getFilialLike = async str =>{
    let request = await query('/admin/filial/like?query='+str+'&limit=50&offset=0')
    let response = await request.json()

    return {response, status: request.status}
}

const getDepartmentLike = async str =>{
    let request = await query('/admin/department/like?query='+str+'&limit=50&offset=0')
    let response = await request.json()

    return {response, status: request.status}
}

const getProgramLike = async str =>{
    let request = await query('/content/program/like?query='+str+'&limit=50&offset=0')
    let response = await request.json()

    return {response, status: request.status}
}

const getCourseLike = async str =>{
    let request = await query('/content/course/like?query='+str+'&limit=50&offset=0')
    let response = await request.json()

    return {response, status: request.status}
}

const getLectureLike = async str =>{
    let request = await query('/content/lecture/like?query='+str+'&limit=50&offset=0')
    let response = await request.json()

    return {response, status: request.status}
}

const getDepartment = async id =>{
    id ? id = `/${id}` : id = ''
    let request = await query(`/admin/department${id}?limit=50&offset=0`)
    let response = await request.json()

    return {response, status: request.status}
}
///admin/filial${id && '/'+id}?limit=50&offset=0

const setFilial = async body =>{
    const options = await {...params, method: 'POST', body: JSON.stringify(body)}

    let request = await query('/admin/filial', options)
    let response = await request.json()

    return {...response, status: request.status}
}

const setReadLecture = async id =>{
    const options = await {...params, method: 'POST'}

    let request = await query(`/content/lecture/read/${id}`, options)
    let response = await request.json()

    return {...response, status: request.status}
}

const setDepartment = async body =>{
    const options = await {...params, method: 'POST', body: JSON.stringify(body)}

    let request = await query('/admin/department', options)
    let response = await request.json()

    return {...response, status: request.status}
}

const programInActive = async (id) =>{
    const options = await {...params, method: 'DELETE' }

    let request = await query('/content/program/'+id, options)
    let response = await request.json()
    return {response, status: request.status}
}

const programActive = async (id) =>{
    const options = await {...params, method: 'PUT' }

    let request = await query('/content/program/enable/'+id, options)
    let response = await request.json()
    return {response, status: request.status}
}

const courseInActive = async (id) =>{
    const options = await {...params, method: 'DELETE' }

    let request = await query('/content/course/'+id, options)
    let response = await request.json()
    return {response, status: request.status}
}

const courseActive = async (id) =>{
    const options = await {...params, method: 'PUT' }

    let request = await query('/content/course/enable/'+id, options)
    let response = await request.json()
    return {response, status: request.status}
}

const lectureInActive = async (id) =>{
    const options = await {...params, method: 'DELETE' }

    let request = await query('/content/lecture/'+id, options)
    let response = await request.json()
    return {response, status: request.status}
}

const lectureActive = async (id) =>{
    const options = await {...params, method: 'PUT' }

    let request = await query('/content/lecture/enable/'+id, options)
    let response = await request.json()
    return {response, status: request.status}
}

const inclusiveSearchUser = async searchQuery => {
    const request = await query(`/user/like?query=${searchQuery}`, params)
    const response = await request.json()
    return request.status === 200
      ? Promise.resolve(response)
      : Promise.reject(response.error)
}

const inclusiveSearchDepartments = async searchQuery => {
    const request = await query(`/admin/department/like?query=${searchQuery}`, params)
    const response = await request.json()
    return request.status === 200
      ? Promise.resolve(response)
      : Promise.reject(response.error)
}

const inclusiveSearchFilials = async searchQuery => {
    const request = await query(`/admin/filial/like?query=${searchQuery}`, params)
    const response = await request.json()
    return request.status === 200
      ? Promise.resolve(response)
      : Promise.reject(response.error)
}

const inclusiveSearchCompanies = async searchQuery => {
    const request = await query(`/admin/company/like?query=${searchQuery}`, params)
    const response = await request.json()
    return request.status === 200
      ? Promise.resolve(response)
      : Promise.reject(response.error)
}

const getBriefUsersByStructure = async ({ type, id, excludeKey, excludeVal, unlimit = false }) => {
    let queryStr = `/user/filter?brief=true${getMaterialType(type, id)}${getExcludeType(excludeKey, excludeVal)}`
    if (unlimit) queryStr += '&unlimit=true'
    const request = await query(queryStr, params)
    const response = await request.json()
    return request.status === 200 ? Promise.resolve(response) : Promise.reject(response.error)
}

const inclusiveSearchCourses = async searchQuery => {
    const request = await query(`/content/course/like?query=${searchQuery}`, params)
    const response = await request.json()
    return request.status === 200
      ? Promise.resolve(response)
      : Promise.reject(response.error)
}

const inclusiveSearchPrograms = async searchQuery => {
    const request = await query(`/content/program/like?query=${searchQuery}`, params)
    const response = await request.json()
    return request.status === 200
      ? Promise.resolve(response)
      : Promise.reject(response.error)
}

const createTimelines = async body => {
    const requestParams = {...params, method: 'POST', body: JSON.stringify(body)}
    const request = await query(`/user/availabilities`, requestParams)
    const response = await request.json()
    return request.status === 200
      ? Promise.resolve(response)
      : Promise.reject(response.error)
}

const getHistoryReport = async body => {
    const request = await query('/admin/report/history', {
        ...params,
        method: 'POST',
        body: JSON.stringify(body)
    })

    const response = await request.json()
    return request.status === 200
      ? Promise.resolve(response)
      : Promise.reject(response.error)
}

const getAvailabilityReport = async body => {
    const request = await query('/admin/report/availability', {
        ...params,
        method: 'POST',
        body: JSON.stringify(body)
    })

    const response = await request.json()
    return request.status === 200
      ? Promise.resolve(response)
      : Promise.reject(response.error)
}

const fetchUsersAvatars = async body => {
    const request = await query('/user/avatar', {
        ...params,
        method: 'POST',
        body: JSON.stringify(body)
    })

    const response = await request.json()
    return request.status === 200
      ? Promise.resolve(response)
      : Promise.reject(response.error)
}

export default {
    banUser,
    unbanUser,
    programInActive,
    programActive,
    courseActive,
    courseInActive,
    lectureActive,
    lectureInActive,
    setFilial,
    setDepartment,
    setAward,
    setProgram,
    setHelper,
    setCourse,
    setCompany,
    setCategory,
    setLecture,
    setReadLecture,
    setPosition,
    setAvailability,
    setTest,
    setUpload,
    updateCourseOrder,
    updateLectureOrder,
    updateFilialId,
    updateCategoryId,
    updateAnswerBody,
    updateAnswerOption,
    updateHelper,
    getAward,
    getHelper,
    getTaskId,
    getRatings,
    getRatingId,
    getProgrammsAll,
    getProgramms,
    getProgram,
    getHelperId,
    getProgrmTeacher,
    getHistoryAttempts,
    getLecture,
    getLectureId,
    getLesson,
    getHelperMobile,
    getLessonMobile,
    getPermissionList,
    getPermissionUser,
    pathPermissionUser,
    getUpload,
    getCategory,
    getCategoryId,
    getTeacher,
    getAchievement,
    getAchievements,
    getAllAvailability,
    getAllTasks,
    getTest,
    getFilial,
    getTestLike,
    getFilialLike,
    getDepartmentLike,
    getCompanyLike,
    getProgramLike,
    getLectureLike,
    getCourseLike,
    getQuestion,
    deleteAvailability,
    deleteTask,
    deleteQuestion,
    deleteAdditionMaterial,
    deleteAnswer,
    getTestQuestions,
    getDepartment,
    getProfile,
    getCompanyId,
    getFiles,
    getProfiles,
    getProfilesAll,
    getTimeLine,
    getTimeLineId,
    getProfileId,
    getCourseId,
    getCourse,
    getCourses,
    getCompany,
    getPosition,
    setQuestion,
    setAnswer,
    updateAnswer,
    signIn,
    signUp,
    putTeacher,
    updateProfile,
    updateProfileId,
    updateProgramOrder,
    updatePassword,
    updateProgram,
    updateCourse,
    updateCompanyId,
    updateDepartment,
    updateAwards,
    updateTest,
    updatePositionId,
    updateLecture,
    updateTask,
    phoneUpdate,
    emailUpdate,
    phoneConfirm,
    emailConfirm,
    validToken,
    sendAnswer,
    getHistory,
    getHistoryTest,
    finishTest,
    inclusiveSearchUser,
    inclusiveSearchDepartments,
    inclusiveSearchFilials,
    getHistoryReport,
    getAvailabilityReport,
    inclusiveSearchCourses,
    inclusiveSearchPrograms,
    inclusiveSearchCompanies,
    getBriefUsersByStructure,
    createTimelines,
    fetchUsersAvatars,
    recoveryPassword,
    confirmPassword,
    fetchLectureAutogenerateQuestion,
    insertQuestions,
    getProfilesAllLike
}
