import React, { Component } from 'react'
import noImage from '../../../../../img/lecture.svg'
import Tooltip from '@material-ui/core/Tooltip';

export default class BlockEducation extends Component {
    render() {
        let {title, estimation, description, images, after_test, read} = this.props.body
        console.log(read)
        return (
        <div className={`block-education ${!after_test && 'disabled-block-education'}`}>
            <div className="education-cover"><img className={!images ? 'noImageCoverFix' : ''} src={ (images && images.normal) || noImage  } alt=""/></div>
            <div className="education-content">
                <div className="education-content-children education-content-title">
                    <span>{title && title}</span>
                </div>
                <div className="education-content-children education-content-description">
                    <span>{description && description}</span>
                </div>
                <div className="education-content-children education-content-footer">
                    {/* <span><i className="fas fa-star-half-alt"></i>  -</span> */}
                    <Tooltip title="Предположительное время на изучение" aria-label="Предположительное время на изучение"><span >
                        <i className="fal fa-clock"></i> {estimation && estimation/60} минут
                    </span></Tooltip>
                    
                    <span >
                        {read !== undefined &&
                            (
                            read ?
                            <Tooltip title="Материал прочитан" aria-label="Материал прочитан">
                                <i className="fas fa-eye icon-read"></i>
                            </Tooltip>
                            :<Tooltip title="Материал не прочитан" aria-label="Материал не прочитан">
                                <i className="fas fa-eye-slash icon-read-disable"></i>
                            </Tooltip>
                            )
                        }
                    </span>
                    <span className="education-content-stat-avaible">{!after_test ? 'Не доступно' : 'Доступно к выполнению'}</span>
                </div>
            </div>
        </div>
        )
    }
}
