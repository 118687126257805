import {header} from '../../../utilities/page'

const inclusiveSearchReducer = ({ id, title }) => ({ value: id, label: title })

export default (
  searchCallback,
  errorMessage = 'Данный элемент не найден',
  reducer = inclusiveSearchReducer
) => (inputValue) => searchCallback(inputValue)
  .then(res => res.map(reducer))
  .catch(err => {
    if (err.code === 404) {
      header.getSnackbar(errorMessage)
    } else if(err.code === 400) {
      header.getSnackbar('Вы ввели некоректные данные. Поиск осуществляется по буквам и цифрам.')
    } else if(err.code === 403) {
      header.getSnackbar('У вас недостаточно прав для получения данной информации.')
    } else {
      header.getSnackbar('Произошла ошибка на сервере.')
      console.error(err)
    }
  })