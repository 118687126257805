import React from 'react'
import AsyncSelect from 'react-select/async'

export default function({ submit, name, findCallback, placeholder }) {
  const onChange = selected => submit(
    name,
    selected.map(el => el.value)
  )

  return (
    <AsyncSelect
      className="select-report"
      isMulti
      loadOptions={findCallback}
      onChange={onChange}
      placeholder={placeholder}
      noOptionsMessage={() => "..."}
      loadingMessage={() => "Поиск..."}
    />
  )
}
