export default {
  getExcludeType(excludeKey, excludeVal) {
    if (!excludeKey || !excludeVal) return ''
    switch (excludeKey) {
      case 'filial':
      case 'course':
      case 'program': return `&exclude_key=${excludeKey}_id&exclude_val=${excludeVal}`
      default: throw new Error(`getExcludeType wrong type: ${excludeKey}`)
    }
  },

  getMaterialType(type, id) {
    switch (type) {
      case 'departments': return `&department_id=${id}`
      case 'filials': return `&filial_id=${id}`
      case 'companies': return `&company_id=${id}`
      default: throw new Error('getBriefUsersByStructure wrong type passed.')
    }
  },

  isFloat(n){
    return Number(n) === n && n % 1 !== 0
  }
}
