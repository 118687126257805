import {    SHOW_MODAL_PAGE,
            HIDE_MODAL_PAGE,
            SHOW_DIALOG,
            HIDE_DIALOG,
            SHOW_SNACKBAR,
            HIDE_SNACKBAR
} from '../../actions/elements/key'

const client = {
    snackbar: {
        active: false,
        body: null
    },
    dialog: {
        active: false,
        body: {
            title: null,
            content: null
        }
    },
    modalPage:{
        active: false,
        body: {
            title: null,
            content: null
        }
    }
}

export default (state = client, action) => {
    switch (action.type) {
        case SHOW_MODAL_PAGE:
            return {...state, modalPage: {
                active: true,
                body: action.payload
            }}
        case HIDE_MODAL_PAGE:
            return {...state, modalPage: {
                active: false,
                body: null
            }}
        case SHOW_DIALOG:
            return {...state, dialog: {
                active: true,
                body: action.payload
            }}
        case HIDE_DIALOG:
            return {...state, dialog: {
                active: false,
                body: null
            }}
        case SHOW_SNACKBAR:
            return {...state, snackbar:{
                active: true,
                body: action.payload
            }}
        case HIDE_SNACKBAR:
            return {...state, snackbar:{
                active: false,
                body: null
            }}
        default:
            return state
    }
}