import get from 'lodash/get'
import React, { Component } from 'react'
import undraw_questions_75e0 from '../../../../../img/undraw_questions_75e0.svg'
import webApi from '../../../../utilities/web-api';

export default class BlockTest extends Component {
    state = {
        finished: null,
        attempts: null,
        max_attempts: null
    }

    componentWillMount(){
        this.props.test_id && webApi.getHistoryTest(this.props.test_id).then(data => {
            data.status === 200 && this.setState({finished: data.response.success})
        })

        webApi.getHistoryAttempts(this.props.test_id)
        .then(data => data.status === 200 && this.setState({attempts: data.response.attempts}))

        webApi.getTest(this.props.test_id)
        .then(data => data.status === 200 && this.setState({max_attempts: data.response.max_attempts}))
    }

    testStatus = () => {
        let {finished, attempts, max_attempts} = this.state

        if(finished !== null && attempts !== null && max_attempts !== null){
            if((max_attempts - attempts) <= 0 && !finished) return <span className="education-content-stat-avaible font-red">Тест не пройден, посмотреть результат</span>
            if((max_attempts - attempts) > 0 && !finished) return <span className="education-content-stat-avaible href">Пройти тест</span>
            if(finished) return <span className="education-content-stat-avaible href font-green">Тест успешно пройден, посмотреть результат</span>
        }else return <span className="education-content-stat-avaible href">Пройти тест</span>

    }

    endingString = num =>{
        let str = num.toString()
        let ending
        if(str.length === 1){
           // parseInt(num) 1 && 
        }
        console.log(str, str.length)
    }

    render() {
        let {title, time_to_pass, questions_amount, min_score_points, body, test} = this.props
        //questions_amount && this.endingString(questions_amount)
        console.log(body)
        return (
        <div className={`block-education test-education cur-p ${((test && test.after_test === false) || (body && body.test && body.test.after_test === false)) && 'disabled-block-education'}`}>
            <div className="education-cover pd-15">
                <img src={undraw_questions_75e0} alt="undraw_questions"/>
            </div>
            <div className="education-content">
                <div className="education-content-children education-content-title">
                    <span>{title && title}</span>
                </div>
                <div className="education-content-children education-content-description">
                </div>
                <div className="education-content-children education-content-footer">
                    <span><i className="fas fa-star-half-alt"/> {questions_amount && questions_amount} вопросов</span>
                    <span ><i className="fal fa-clock"/> {time_to_pass && (time_to_pass/60).toFixed(2)} минут</span>
                    <span><i className="fas fa-star-half-alt"/> Проходной балл {min_score_points && min_score_points}</span>
                    {this.testStatus()}
                </div>
            </div>
        </div>
        )
    }
}
