import React from 'react'
import get from 'lodash/get'
import isNumber from 'lodash/isNumber'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@material-ui/core'
import helpers from '../../../../../../utilities/helpers'

const styles = () => ({
  root: {
    width: '100%',
    marginTop: 20,
  },
  table: {
    minWidth: '100%',
  },
  notFound: {
    width: '100%',
    textAlign: 'center',
    fontSize: 26,
    fontWeight: 600
  }
})

function FilterTable({ classes, data, notFound }) {
  if (notFound) {
    return <h3 className={classes.notFound}>Не найдены записи удовлетворяющие фильтру.</h3>
  }
  let tResult = []
  Boolean(data.length) && data.map(e =>{
    return tResult = [...tResult, {
      user: `${e.users.surname} ${e.users.name}`,
      departments: get(e, 'departments.title', 'Отдел не указан'),
      filial: get(e, 'filials.title', 'Объект не указан'),
      programs: get(e, 'programs.title', 'Программа не указана'),
      courses: get(e, 'courses.title', 'Курс не указан'),
      lectures: get(e, 'lectures.title', 'Лекция не указана'),
      min_score_points: get(e, 'tests.min_score_points', 0),
      max_score_points: `${get(e, 'points', 0)}/${get(e, 'tests.max_score_points', 0)}`,
      success: e.success === true ? 'Успешно сдан' : 'Не сдан'
    }]
  })

  function getMaxScorePoints(row) {
    return Number(
      row.max_score_points
      || get(row, 'tests.max_score_points')
      || get(row, 'question_amount')
      || 0
    )
  }

  function getMinScorePoints(row) {
    return isNumber(row.min_score_points)
      ? row.min_score_points
      : get(row, 'tests.min_score_points', 0)
  }

  function getResults(row) {
    const maxScorePoints = getMaxScorePoints(row)
    let points = get(row, 'points', 0)
    points = points < maxScorePoints ? points : maxScorePoints
    return `${points}/${maxScorePoints}`
  }

  function getPerformancePercentage(row) {
    const maxScorePoints = getMaxScorePoints(row)
    let points = get(row, 'points', 0)
    points = points < maxScorePoints ? points : maxScorePoints
    const result = points/maxScorePoints * 100
    return `${helpers.isFloat(result) ? result.toFixed(1) : result}%`
  }

  function convertToCSV(objArray) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray
    let str = ''
    for (let i = 0; i < array.length; i++) {
        let line = ''
        for (let index in array[i]) {
            if (line !== '') line += ','
            line += array[i][index]
        }
        str += line + '\r\n'
    }
    return str
}

function exportCSVFile(headers, items, fileTitle) {
    if (headers) items.unshift(headers)
    let jsonObject = JSON.stringify(items)
    let csv = '\uFEFF' + convertToCSV(jsonObject)
    let exportedFilenmae = fileTitle + '.csv' || 'export.csv'
    let blob = new Blob([csv], { type: 'data:text/csvcharset=utf-8' })
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, exportedFilenmae)
    } else {
        let link = document.createElement("a")
        if (link.download !== undefined) {
            let url = URL.createObjectURL(blob)
            link.setAttribute("href", url)
            link.setAttribute("download", exportedFilenmae)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    }
}

function download(){
  let headers = {
      user: 'Пользователь'.replace(/,/g, ''),
      departments: "Отдел",
      filial: "Объект",
      programs: "Программа",
      courses: "Курс",
      lectures: "Лекция",
      min_score_points: "Порог сдачи",
      max_score_points: "Результат",
      success: "Статус теста",
  }

  let itemsNotFormatted = tResult

  let itemsFormatted = []

  itemsNotFormatted.forEach((item) => {
      itemsFormatted.push({
          user: item.user.replace(/,/g, ''),
          departments: item.departments,
          filial: item.filial,
          programs: item.programs,
          courses: item.courses,
          lectures: item.lectures,
          min_score_points: item.min_score_points,
          max_score_points: item.max_score_points,
          success: item.success,
      })
  })

  let fileTitle = `task_${Date.now()}`

  exportCSVFile(headers, itemsFormatted, fileTitle) // call the exportCSVFile() function to process the JSON and trigger the download
}

  return Boolean(data.length) && (
    <Paper className={classes.root+" table-report"}>
      <br/>
      <span onClick={() => download()} className="href pd-15 mg-tb-5"><i className="fal fa-file-export"></i> Экспортировать в *csv</span>
      <br/><br/>
      <Table className={classes.table + ' report-test-export'}>
        <TableHead className="table-report-head">
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell align="right">Пользователь</TableCell>
            <TableCell align="right">Отдел</TableCell>
            <TableCell align="right">Объект</TableCell>
            <TableCell align="right">Программа</TableCell>
            <TableCell align="right">Курс</TableCell>
            <TableCell align="right">Лекция</TableCell>
            <TableCell align="right">Порог сдачи</TableCell>
            <TableCell align="right">Результат</TableCell>
            <TableCell align="right">Процент</TableCell>
            <TableCell align="right">Статус теста</TableCell>
            <TableCell align="right">Дата</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">{row.id}</TableCell>
              <TableCell align="right">{`${row.users.surname} ${row.users.name}`}</TableCell>
              <TableCell align="right">{get(row, 'departments.title', 'Отдел не указан')}</TableCell>
              <TableCell align="right">{get(row, 'filials.title', 'Объект не указан')}</TableCell>
              <TableCell align="right">{get(row, 'programs.title', 'Программа не указана')}</TableCell>
              <TableCell align="right">{get(row, 'courses.title', 'Курс не указан')}</TableCell>
              <TableCell align="right">{get(row, 'lectures.title', 'Лекция не указана')}</TableCell>
              <TableCell align="right">{getMinScorePoints(row)}</TableCell>
              <TableCell align="right">{getResults(row)}</TableCell>
              <TableCell align="right">{getPerformancePercentage(row)}</TableCell>
              <TableCell align="right">{row.success === true ? 'Успешно сдан' : 'Не сдан'}</TableCell>
              <TableCell align="right">{row.finished_at ? new Date(row.finished_at).toLocaleDateString("ru-RU")  : ''}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  )
}

FilterTable.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FilterTable)
