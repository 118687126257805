import React, { Component } from 'react'
import webApi from '../../../../../../utilities/web-api';
import { header } from './../../../../../../utilities/page';
import { connect } from 'react-redux';
import Select from 'react-select';
import permissionTranslate from './permissionTranslate'
import history from '../../../history';
import InputMask from 'react-input-mask';

class EditUser extends Component {
    state = {
        create: false,
        permissionsList: null,
        permissions:[],
        user: null,
        update: {},
        role: 'admin',
        filialSearch: [],
        company_id: null,
        filialDepartment: [],
        defCompany: null
    }

    async componentWillMount(){
        let objState = await {}

        webApi.getCompany().then(data => data.status === 200 && this.setState({company: data.response}))

        await webApi.getProfileId(this.props.id_user)
              .then(data => {
                  if(data.status === 200){
                    webApi.getCompanyId(data.response.company_id).then(data => {
                        if(data.status === 200){
                            this.setState({
                                defCompany:{
                                    id: data.response[0].id,
                                    title: data.response[0].title
                                }
                            })
                        }
                    })

                    //console.log(data.response)

                      objState = data.response
                      objState = {
                          ...objState,
                          company_id: this.props.company_id,
                      }
                    }
              })
        

        await objState && this.setState({user: objState}, () => {
            this.state.user.filial_id && webApi.getFilial(this.state.user.filial_id)
            .then(data => {
                if(data.status === 200){
                    this.setState({user:{...this.state.user, filial_title: data.response[0].title }})
                }
            })

            webApi.getFilial().then(data => data.status === 200 && this.setState({filials: data.response}))
            webApi.getDepartment().then(data => data.status === 200 && this.setState({departments: data.response}))

            this.state.user.department_id && webApi.getDepartment(this.state.user.department_id)
            .then(data => {
                if(data.status === 200){
                    this.setState({user:{...this.state.user, department_title: data.response[0].title }})
                }
            })
            webApi.getPermissionUser(this.state.user.id).then(data => {
                if(data.status === 200) {
                    let permission = data.response
                    // permission = [...permission, {type: 'help', write: false, read: false}]
                    this.setState({permissionsList: permission})

                }
                }).catch(error => console.log(error))

        })
    }

    updateProfile = () => {
        //console.log(this.state.update)
        Object.keys(this.state.update).length && webApi.updateProfileId(this.state.update,this.state.user.id)
        .then(data => {
            if(data.status === 200) header.getSnackbar("Данные успешно изменены")
        }
        ).catch(error => console.log(error))

            webApi.pathPermissionUser(this.state.user.id, this.state.permissions).then(data => {
                data.status === 200 && header.getSnackbar("Данные успешно изменены")
            }).catch(error => console.log(error))
    }

    handleInput = e => {
        this.setState({update:{...this.state.update, [e.target.name]:e.target.value}})
    }

    onChangeSelect = e => {
        this.setState({update:{...this.state.update, company_id: e.id}})
    }

    handleCheck = e =>{
        let check = e.currentTarget
        let obj = this.state.permissions

        let read = document.querySelectorAll(`input[data-type-name=${check.dataset.typeName}]`)[0]
        if(check.dataset.type === "write" && check.checked && !read.checked) {
            read.checked = true
            read.disabled = true
        } else {read.disabled = false}

        let res = obj.filter(el => {
            return el.type === check.dataset.typeName
        })

        if(res[0]){
            let newObj = res[0]
            newObj[check.dataset.type] = check.checked
            if(newObj.write === true && newObj.read === false){
                newObj.read = true
            }
            this.setState([...obj, newObj])
        }else{
            let newObj = {
                type: check.dataset.typeName,
                read: document.querySelectorAll(`input[data-type-name=${check.dataset.typeName}]`)[0].checked,
                write: document.querySelectorAll(`input[data-type-name=${check.dataset.typeName}]`)[1].checked
            }
            if(newObj.write === true && newObj.read === false){
                newObj.read = true
            }
            newObj = [...obj, newObj]
            this.setState({permissions: newObj})
        }
    }

    handleSelect = e => {
        this.setState({update:{...this.state.update, filial_id: e.id}}, () => console.log(this.state.update))
    }

    handleSelectRole = e => {
        const perm = {
            student: [
                {read: true, write: false, type: "help"},
                {read: false, write: false, type: "reports"},
                {read: false, write: false, type: "journals"},
                {read: false, write: false, type: "permissions"},
                {read: true, write: false, type: "plans"},
                {read: true, write: false, type: "results"},
                {read: true, write: false, type: "users"},
                {read: true, write: false, type: "organizational_structure"},
                {read: true, write: false, type: "trainings"},
                {read: true, write: false, type: "tests"},
                {read: true, write: false, type: "materials"}
            ],
            teacher: [
                {read: true, write: false, type: "help"},
                {read: true, write: false, type: "reports"},
                {read: true, write: false, type: "journals"},
                {read: true, write: false, type: "permissions"},
                {read: true, write: true, type: "plans"},
                {read: true, write: false, type: "results"},
                {read: true, write: false, type: "users"},
                {read: true, write: false, type: "organizational_structure"},
                {read: true, write: true, type: "trainings"},
                {read: true, write: true, type: "tests"},
                {read: true, write: true, type: "materials"}
            ],
            admin: [
                {read: true, write: true, type: "help"},
                {read: true, write: true, type: "reports"},
                {read: true, write: true, type: "journals"},
                {read: true, write: true, type: "permissions"},
                {read: true, write: true, type: "plans"},
                {read: true, write: true, type: "results"},
                {read: true, write: true, type: "users"},
                {read: true, write: true, type: "organizational_structure"},
                {read: true, write: true, type: "trainings"},
                {read: true, write: true, type: "tests"},
                {read: true, write: true, type: "materials"}
            ]
        }
        this.setState({
            update:{...this.state.update, role: e.value},
            permissions:  perm[e.value],
            permissionsList: perm[e.value]

        }, () => console.log(perm))
    }

    handleSelectDepartment = e => {
        this.setState({update:{...this.state.update, department_id: e.id}})
    }

    handleSearchFilial = e => {
        e.length > 0 ? webApi.getFilialLike(e)
        .then(data => {
            data.response.length && this.setState({
                filials: data.response
            })
        }) : this.setState({filials: []})
    }

    handleSearchDepartment = e => {
        e.length > 0 ? webApi.getDepartmentLike(e)
        .then(data => {
            data.response.length && this.setState({
                filialDepartment: data.response
            })
        }) : this.setState({filialDepartment: []})
    }

    handleSearchIndex = (arr, attr, value) => {
        for(var i = 0; i < arr.length; i += 1) {
            if(arr[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    deleteUser = () => {
        let { id } = this.state.user
        webApi.banUser(id)
        .then(data => {
            if(data.status === 200){
                header.getSnackbar("Пользователь заблокирован")
                this.props.handlePage('view')
                header.closeDialog()
            }
            else{
                header.getSnackbar("Произошла ошибка")
                header.closeDialog()
            }
        })
    }

    dialogBlockUser = () =>{
        return(
            <div className="mgt-25">
                Вы уверены, что хотите заблокировать пользователя?
                <div onClick={this.deleteUser} className="btn-red fl-left mgt-25">Заблокировать</div>
                <br/><br/>
            </div>
        )
    }

    unBanUser = () => {
        let { id } = this.state.user
        webApi.unbanUser(id)
        .then(data => {
            if(data.status === 200){
                header.getSnackbar("Пользователь разблокирован")
                this.props.handlePage('view')
                header.closeDialog()
            }
            else{
                header.getSnackbar("Произошла ошибка")
                header.closeDialog()
            }
        })
    }

    dialogUnBlockUser = () =>{
        return(
            <div className="mgt-25">
                Вы уверены, что хотите разблокировать пользователя?
                <div onClick={this.unBanUser} className="btn-red fl-left mgt-25">Разблокировать</div>
                <br/><br/>
            </div>
        )
    }

    beforeMaskedStateChange = ({ nextState }) => {
        let { value } = nextState;
        if (value.endsWith("/")) {
          value = value.slice(0, -1);
        }

        // console.log({
        //     ...nextState,
        //     value
        //   })

        console.log(nextState, value)
      
        return {
          ...nextState,
          value
        };
      }

    render() {
        let role = [
            {value: 'student', label: 'Студент'},
            {value: 'teacher', label: 'Преподаватель'},
            {value: 'admin', label: 'Администратор'},
        ]

        let objTitle

        if(this.state.user && this.state.user.filial_title) {
            objTitle = {id: this.state.user.filial_id, title: this.state.user.filial_title}
            //console.log(objTitle)
        }

        let depTitle

        if(this.state.user && this.state.user.department_title) {
            depTitle = {id: this.state.user.department_id, title: this.state.user.department_title}
            //console.log(depTitle)
        }

        //console.log(this.state.permissionsList)

        return this.state.user && (
            <div>
                <span className="href" onClick={() => this.props.handlePage('view')}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад</span><br/><br/>
                {!this.state.user.active &&
                <div className="error_msg_test w-50">
                    <i className="fas fa-user-lock"></i> Пользователь заблокирован
                    <div onClick={() => header.getDialog({title: "Активация пользователя", content: this.dialogUnBlockUser()})} className="btn-red fl-right mglr-10">Разблокировать</div>
                    <div className="clear"></div>
                </div>
                }
                <div className="create-form">
                    <span><b>Компания</b></span> <br/>
                    {/*<input defaultValue={this.props.company ? this.props.company : 'Вы не привязаны ни к одной компании'} type="text" disabled/>*/}
                    {this.state.defCompany && <Select
                        onChange={this.onChangeSelect}
                        getOptionValue={(option) => (option['id'])}
                        getOptionLabel={(option) => (option['title'])}
                        options = {this.state.company || [{id: 0, title: "Нет ни одной компании"}]}
                        defaultValue={this.state.defCompany}
                        placeholder="Выберите компанию"
                    />}
                    {!this.state.defCompany && <Select
                        onChange={this.onChangeSelect}
                        getOptionValue={(option) => (option['id'])}
                        getOptionLabel={(option) => (option['title'])}
                        options = {this.state.company || [{id: 0, title: "Нет ни одной компании"}]}
                        placeholder="Выберите компанию"
                    />}
                    <br/>
                    <span><b>Имя</b></span> <br/>
                    <input defaultValue={this.state.user.name} name = "name" onChange={this.handleInput} type="text" placeholder="Введите имя"/> <br/><br/>
                    <span><b>Фамилия</b></span> <br/>
                    <input defaultValue={this.state.user.surname} name = "surname" onChange={this.handleInput} type="text" placeholder="Введите фамилию"/> <br/><br/>
                    <span><b>Email</b></span> <br/>
                    <input defaultValue={this.state.user.email || '-'} name = "email" onChange={this.handleInput} type="text" placeholder="Введите адрес электронной почты" disabled/> <br/><br/>
                    <span><b>Телефон</b></span> <br/>
                    <input defaultValue={this.state.user.phone || '-'} name = "phone" onChange={this.handleInput} type="text" placeholder="Введите номер телефона" disabled/> <br/><br/>
                    <span><b>Объект</b></span> <br/>
                    {this.state.filials && <Select placeholder="Выберите объект"
                    getOptionValue={(option) => (option['id'])}
                    getOptionLabel={(option) => (option['title'])}
                    defaultValue={this.state.filials.filter(e => e.id === this.state.user.filial_id)} //this.state.user.filial_id
                    onInputChange={this.handleSearchFilial}
                    onChange={this.handleSelect}
                    name="filialSearch"
                    noOptionsMessage = {() => '...'}
                    allowCreateWhileLoading
                    options={this.state.filials}
                    />}
                    {!this.state.filials && <Select placeholder="Выберите объект"
                    getOptionValue={(option) => (option['id'])}
                    getOptionLabel={(option) => (option['title'])}
                    onInputChange={this.handleSearchFilial}
                    onChange={this.handleSelect}
                    name="filialSearch"
                    allowCreateWhileLoading
                    noOptionsMessage = {() => '...'}
                    options={this.state.filialSearch}
                    />}
                    <br/>
                    {/* //departments */}
                    <span><b>Отдел</b></span> <br/>
                    {this.state.departments && <Select placeholder="Выберите отдел"
                    getOptionValue={(option) => (option['id'])}
                    getOptionLabel={(option) => (option['title'])}
                    noOptionsMessage = {() => '...'}
                    //onInputChange={this.handleSearchDepartment}
                    onChange={this.handleSelectDepartment}
                    //defaultInputValue={this.state.user.department_title}
                    defaultValue={this.state.departments.filter(e => e.id === this.state.user.department_id)}
                    name="filialSearch"
                    allowCreateWhileLoading
                    options={this.state.departments}
                    />}
                    {!this.state.departments && <Select placeholder="Выберите отдел"
                    getOptionValue={(option) => (option['id'])}
                    getOptionLabel={(option) => (option['title'])}
                    //onInputChange={this.handleSearchDepartment}
                    onChange={this.handleSelectDepartment}
                    //defaultInputValue={this.state.user.department_title}
                    name="filialSearch"
                    noOptionsMessage = {() => '...'}
                    allowCreateWhileLoading
                    options={this.state.filialDepartment}
                    />}
                    <br/>
                    <span><b>Роль</b></span> <br/>
                    <Select defaultValue={role[this.handleSearchIndex(role, 'value', this.state.user.role)]} placeholder="Выберите роль пользователя" onChange={this.handleSelectRole} name="role" options={role} /> <br/><br/>
                    {!this.state.create &&
                    <div className="permitted-block">
                    <div className="permitted-title">Права</div>
                    {this.state.permissionsList && this.state.permissionsList.map((el, i)=>{
                        //console.log(el)
                        return(<div key={i}>
                        <div className="permitted-row">
                            <div className="fl-left w-50">{permissionTranslate(el.type)}</div>
                            <div className="fl-right w-50 fl-left">
                                <div className="fl-left w-50">
                                   <input type="checkbox" data-type-name={el.type} data-type="read" checked={el.read} onChange={this.handleCheck} className="input-checkbox fl-left"/><span className="fl-left" >Чтение</span>
                                </div>
                                <div className="fl-left w-50">
                                    <input type="checkbox" data-type-name={el.type} data-type="write" checked={el.write} onChange={this.handleCheck} className="input-checkbox fl-left"/><span className="fl-left">Запись</span>
                                </div>
                            </div>
                            <div className="clear"></div>
                        </div><div className="hr"></div>
                        </div>)
                    })}
                    </div>}
                    <div className="btnSaveProfile fl-left" onClick={this.updateProfile}>Сохранить</div>
                    {
                        this.state.user.active &&
                        <div onClick={() => header.getDialog({title: "Блокировка пользователя", content: this.dialogBlockUser()})} className="btn-red fl-left mglr-10"><i className="fas fa-lock-alt"></i> Заблокировать</div> 
                        // <div onClick={() => header.getDialog({title: "Блокировка пользователя", content: this.dialogBlockUser()})} className="btn-red fl-left mglr-10"><i className="fas fa-unlock-alt"></i> Разблокировать</div>
                    }
                    {/* <div className="default-btn fl-left mglr-10" onClick={() => history.push('/profile/' + this.state.user.id)}>Профиль пользователя</div> */}
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        company_id: state.user.user_data.company_id
    }
}
export default connect(mapStateToProps)(EditUser)
