import { SET_USER, TOKEN, CLEAR_TOKEN } from '../../actions/client/key'

const client = {
    auth: false,
    token: undefined,
    user_data: undefined
}

export default (state = client, action) => {
    switch (action.type) {
        case SET_USER:
            return {...state, user_data: action.payload}
        case TOKEN:
            return {...state, token: action.payload}
        case CLEAR_TOKEN:
            return {...state, token: null}
        default:
            return state
    }
}