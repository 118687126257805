import React, { Component } from 'react'
import webApi from '../../../../../../utilities/web-api';
import { header } from './../../../../../../utilities/page';
import { connect } from 'react-redux';
import Select from 'react-select';
import permissionTranslate from './permissionTranslate'
import InputMask from 'react-input-mask';

class CreateUser extends Component {
    state = {
        create: false,
        permissionsList: null,
        permissions:[],
        update:{},
        company_id: null,
        company: null
    }

    componentWillMount(){
        this.setState({company_id: this.props.company_id}, () => console.log(this.state.company_id))
        webApi.getCompany().then(data => data.status === 200 && this.setState({company: data.response}, () => console.log(this.state)))
    }

    handleCreate = () => {
        let {email, phone} = this.state
        // if(!email) delete this.state.email
        // else if(!phone) delete this.state.phone
        // else if (!email && !phone) return header.getSnackbar("Email либо номер телефона обязательны для заполнения")

        let obj = {email, phone}

        !obj.email && delete obj.email
        !obj.phone && delete obj.phone

        if(!obj.email && !obj.phone) return header.getSnackbar("Email или номер телефона обязательны для заполнения")

        webApi.signUp(obj)
        .then(async data => {
            if(data.status === 200 || data.status === 201){
                this.state.phone && await delete this.state.phone
                this.state.email && await delete this.state.email
                await delete this.state.create
                await delete this.state.company
                await delete this.state.permissionsList
                await delete this.state.permissions
                await delete this.state.update
                let data_id = data.createdUser.id
                localStorage.setItem('_ids', data.createdUser.id)
                await webApi.updateProfileId(this.state, data.createdUser.id)
                .then(data => {
                    webApi.getCompany().then(data => data.status === 200 && this.setState({company: data.response}, () => console.log(this.state)))
                    webApi.getFilial().then(data => data.status === 200 && this.setState({filials: data.response}))
                    webApi.getDepartment().then(data => data.status === 200 && this.setState({departments: data.response}))

                    if(data.status === 200 || data.status === 201){
                        header.getSnackbar("Новый пользователь создан и ждёт активации")
                        this.setState({
                            create:true,
                            id_user: data_id,
                            permissionsList: [],
                            permissions:[],
                            update:{}
                        })

                        webApi.getPermissionUser(data_id)
                        .then(data => {
                            this.setState({permissionsList: data.response})
                        })
                        .catch(error => console.log(error))
                    }else{
                        header.getSnackbar("Некорректно заполнены данные, либо такой пользователь уже существует")
                    }
                })
            }
            else if (data.status === 400 || data.status === 510) header.getSnackbar("Некорректно заполнены данные, либо такой пользователь уже существует")
        })
    }

    updateProfile = () => {
        webApi.updateProfileId(this.state.update,this.state.id_user).then(data => {
            if(data.status === 200) header.getSnackbar("Данные успешно изменены")
            webApi.pathPermissionUser(this.state.id_user, this.state.permissions).then(data => {
                data.status === 200 && header.getSnackbar("Права успешно изменены")
            }).catch(error => console.log(error))
        }).catch(error => console.log(error))
    }

    handleInput = e => {
        !this.state.create ? this.setState({[e.target.name]:e.target.value}) : this.setState({update:{...this.state.update,[e.target.name]:e.target.value}})
    }

    handleInputPhone = phone => {
        !this.state.create ? this.setState({phone}) : this.setState({update:{...this.state.update, phone}})
    }

    onChangeSelect = e => {
        this.setState({company_id: e.id})
    }

    handleCheck = e =>{
        let check = e.currentTarget
        let obj = this.state.permissions

        let read = document.querySelectorAll(`input[data-type-name=${check.dataset.typeName}]`)[0]
        if(check.dataset.type === "write" && check.checked && !read.checked) {
            read.checked = true
            read.disabled = true
        } else {read.disabled = false}

        let res = obj.filter(el => {
            //el.type === el.type && delete this.state[el.type]
            return el.type === check.dataset.typeName
           //return type
        })

        if(res[0]){
            let newObj = res[0]
            newObj[check.dataset.type] = check.checked
            if(newObj.write === true && newObj.read === false){
                newObj.read = true
            }
            console.log('true', newObj)
            this.setState([...obj, newObj])
        }else{
            let newObj = {
                type: check.dataset.typeName,
                read: document.querySelectorAll(`input[data-type-name=${check.dataset.typeName}]`)[0].checked,
                write: document.querySelectorAll(`input[data-type-name=${check.dataset.typeName}]`)[1].checked
            }
            if(newObj.write === true && newObj.read === false){
                newObj.read = true
            }
            newObj = [...obj, newObj]
            this.setState({permissions: newObj})
        }
    }

    handleSelect = e => {
        const perm = {
            student: [
                {read: true, write: false, type: "help"},
                {read: false, write: false, type: "reports"},
                {read: false, write: false, type: "journals"},
                {read: false, write: false, type: "permissions"},
                {read: true, write: false, type: "plans"},
                {read: true, write: false, type: "results"},
                {read: true, write: false, type: "users"},
                {read: true, write: false, type: "organizational_structure"},
                {read: true, write: false, type: "trainings"},
                {read: true, write: false, type: "tests"},
                {read: true, write: false, type: "materials"}
            ],
            teacher: [
                {read: true, write: false, type: "help"},
                {read: true, write: false, type: "reports"},
                {read: true, write: false, type: "journals"},
                {read: true, write: false, type: "permissions"},
                {read: true, write: true, type: "plans"},
                {read: true, write: false, type: "results"},
                {read: true, write: false, type: "users"},
                {read: true, write: false, type: "organizational_structure"},
                {read: true, write: true, type: "trainings"},
                {read: true, write: true, type: "tests"},
                {read: true, write: true, type: "materials"}
            ],
            admin: [
                {read: true, write: true, type: "help"},
                {read: true, write: true, type: "reports"},
                {read: true, write: true, type: "journals"},
                {read: true, write: true, type: "permissions"},
                {read: true, write: true, type: "plans"},
                {read: true, write: true, type: "results"},
                {read: true, write: true, type: "users"},
                {read: true, write: true, type: "organizational_structure"},
                {read: true, write: true, type: "trainings"},
                {read: true, write: true, type: "tests"},
                {read: true, write: true, type: "materials"}
            ]
        }
        this.setState({
            role: e.value,
            permissions:  perm[e.value],
            permissionsList: perm[e.value]
        })
    }

    handleSelectFilial = e => {
        this.setState({update:{...this.state.update, filial_id: e.id}})
    }

    handleSelectPost = e => {
        this.setState({update:{...this.state.update, position: e.id}})
    }

    handleSelectDepartment = e => {
        this.setState({update:{...this.state.update, department_id: e.id}})
    }

    handleSearchFilial = e => {
        e.length > 0 ? webApi.getFilialLike(e)
        .then(data => {
            data.response.length && this.setState({
                filials: data.response
            })
        }) : this.setState({filials: []})
    }

    handleSearchDepartment = e => {
        e.length > 0 ? webApi.getDepartmentLike(e)
        .then(data => {
            data.response.length && this.setState({
                filialDepartment: data.response
            })
        }) : this.setState({filialDepartment: []})
    }

    render() {
        let role = [
            {value: 'student', label: 'Студент'},
            {value: 'teacher', label: 'Преподаватель'},
            {value: 'admin', label: 'Администратор'}
        ]

        return (
            <div>
                <span className="href" onClick={() => this.props.handlePage('view')}><i className="fas fa-arrow-left color-blue"></i> Вернуться назад</span><br/><br/>
                <div className="create-form">
                    <span><b>Компания</b></span> <br/>
                    {/* <input defaultValue={this.props.company ? this.props.company : 'Вы не привязаны ни к одной компании'} type="text" disabled/> <br/><br/> */}
                    <Select
                        onChange={this.onChangeSelect}
                        getOptionValue={(option) => (option['id'])}
                        getOptionLabel={(option) => (option['title'])}
                        options = {this.state.company || [{id: 0, title: "Нет ни одной компании"}]}
                        placeholder="Выберите компанию"
                    /> <br/>
                    <span><b>Имя</b></span> <br/>
                    <input name = "name" onChange={this.handleInput} type="text" placeholder="Введите имя"/> <br/><br/>
                    <span><b>Фамилия</b></span> <br/>
                    <input name = "surname" onChange={this.handleInput} type="text" placeholder="Введите фамилию"/> <br/><br/>
                    <span><b>Email</b></span> <br/>
                    <input name = "email" onChange={this.handleInput} type="text" placeholder="Введите адрес электронной почты"/> <br/><br/>
                    <span><b>Телефон</b></span> <br/>
                    <InputMask mask="+375(99)999-99-99" alwaysShowMask={true} onChange={e => this.handleInputPhone((e.target.value).replace(/[-+_()\s]/g, ''))} />
                    {/* <input name = "phone" onChange={this.handleInput} type="text" placeholder="Введите номер телефона"/> <br/><br/> */}<br/><br/>
                    <span><b>Роль</b></span> <br/>
                    {/* <input name = "role" onChange={this.handleInput} type="text" placeholder="Выберите роль пользователя"/> <br/><br/> */}
                    <Select
                        defaultValue={[role[0]]}
                        placeholder="Выберите роль пользователя"
                        onChange={this.handleSelect}
                        name="role"
                        options={role}
                    />
                    <br/><br/>
                    {/* <span><b>Должность</b></span> <br/>
                    <Select
                        //defaultValue={[role[0]]}
                        getOptionValue={(option) => (option['id'])}
                        getOptionLabel={(option) => (option['title'])}
                        placeholder="Выберите должность пользователя"
                        onChange={this.handleSelectPost}
                        name="post"
                        options={role}
                    /> */}
                    <br/><br/>
                    {this.state.create &&
                    <div className="permitted-block">
                    <div className="permitted-title">Права</div>
                    <span><b>Объект</b></span> <br/>
                    {this.state.filials && <Select placeholder="Выберите объект"
                        getOptionValue={(option) => (option['id'])}
                        getOptionLabel={(option) => (option['title'])}
                        onInputChange={this.handleSearchFilial}
                        onChange={this.handleSelectFilial}
                        name="filialSearch"
                        allowCreateWhileLoading
                        options={this.state.filials}
                        noOptionsMessage = {() => '...'}
                    />}
                    {!this.state.filials && 
                        <Select placeholder="Выберите объект"
                        getOptionValue={(option) => (option['id'])}
                        getOptionLabel={(option) => (option['title'])}
                        onInputChange={this.handleSearchFilial}
                        onChange={this.handleSelectFilial}
                        name="filialSearch"
                        allowCreateWhileLoading
                        options={this.state.filialSearch}
                        noOptionsMessage = {() => '...'}
                    />
                    }
                    <br/>
                    <span><b>Отдел</b></span> <br/>
                    {
                        this.state.departments &&
                        <Select placeholder="Выберите отдел"
                            getOptionValue={(option) => (option['id'])}
                            getOptionLabel={(option) => (option['title'])}
                            onInputChange={this.handleSearchDepartment} onChange={this.handleSelectDepartment}
                            name="filialSearch"
                            allowCreateWhileLoading
                            options={this.state.departments}
                            noOptionsMessage = {() => '...'}
                        />
                    }
                    {
                        !this.state.departments &&
                        <Select placeholder="Выберите отдел"
                            getOptionValue={(option) => (option['id'])}
                            getOptionLabel={(option) => (option['title'])}
                            onInputChange={this.handleSearchDepartment} onChange={this.handleSelectDepartment}
                            name="filialSearch"
                            allowCreateWhileLoading
                            options={this.state.filialDepartment}
                            noOptionsMessage = {() => '...'}
                        />
                    }
                    <br/><br/>
                    {this.state.permissionsList && this.state.permissionsList.map((el, i)=>{
                        return(<div key={i}>
                        <div className="permitted-row">
                            <div className="fl-left w-50">{permissionTranslate(el.type)}</div>
                            <div className="fl-right w-50 fl-left">
                                <div className="fl-left w-50">
                                   <input type="checkbox" data-type-name={el.type} data-type="read" checked={el.read} onChange={this.handleCheck} className="input-checkbox fl-left"/><span className="fl-left" >Чтение</span>
                                </div>
                                <div className="fl-left w-50">
                                    <input type="checkbox" data-type-name={el.type} data-type="write" checked={el.write} onChange={this.handleCheck} className="input-checkbox fl-left"/><span className="fl-left">Запись</span>
                                </div>
                            </div>
                            <div className="clear"></div>
                        </div><div className="hr"></div>
                        </div>)
                    })}
                    </div>}
                    <div className="btnSaveProfile fl-left" onClick={!this.state.create ? this.handleCreate : this.updateProfile}>Создать</div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        company_id: state.user.user_data.company_id
    }
}
export default connect(mapStateToProps)(CreateUser)
